import {Injectable} from '@angular/core';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, Platform} from '@ionic/angular';

@Injectable()
export class LocationTracker {

  constructor(private diagnostic: Diagnostic,
              private alertCtrl: AlertController,
              private translate: TranslateService,
              private platform: Platform,
              private geolocation: Geolocation) {
  }

  async checkForLocation() {
    const self = this;
    return new Promise((resolve, reject) => {
      if (self.platform.is('cordova')) {
        self.checkForGpsEnabled().then(() => {
          self.geolocation.getCurrentPosition({enableHighAccuracy: true}).then((data) => {
            if (data.coords != undefined && data.coords.longitude != undefined && data.coords.latitude != undefined
              && data.coords.longitude > 0 && data.coords.latitude > 0) {
              resolve(data);
            } else {
              reject();
            }
          }).catch((err) => {
            console.error(err);
            reject();
          });
        }).catch(() => {
          reject();
        });
      } else {
        self.geolocation.getCurrentPosition({enableHighAccuracy: true}).then(data => {
          if (data.coords != undefined && data.coords.longitude != undefined && data.coords.latitude != undefined
            && data.coords.longitude > 0 && data.coords.latitude > 0) {
            resolve(data);
          } else {
            reject();
          }
        }).catch((err) => {
          console.error(err);
          reject();
        });
      }

    });
  }

  checkForGpsEnabled() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.diagnostic.isLocationEnabled().then(async (enabled) => {
        if (!enabled) {
          const alert = await self.alertCtrl.create({
            header: self.translate.instant('generic.activateGps.title'),
            buttons: [{
              text: self.translate.instant('generic.activateGps.button'),
              handler: () => {
                self.diagnostic.switchToLocationSettings();
              }
            }]
          });
          await alert.present();
          reject();
        } else {
          resolve({});
        }
      });
    });

  }

}
