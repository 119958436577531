import moment from 'moment';

export interface IRouteSchema {
    _id?: string;
    accountId?: string;
    deliveryAccountId?: string;
    deliveryDate?: Date;
    accountName?: string;
    route?: Array<RouteResponse>;
}

export interface RouteResponse {
    bounds?: Array<Array<number>>;
    departure?: {
        location?: Array<number>;
        address?: string;
        time?: Date;
    };
    arrival?: {
        location?: Array<number>;
        address?: string;
        time?: Date;
    };
    deliveryPoints?: Array<DeliveryPoints>;
    totalTravelDuration?: number;
    totalTravelDistance?: number;
    totalStops?: number;
    overviewRoute?: string;
    routedOrders?: Array<string>;
    time?: string;
    routeColor?: string;
    pinColor?: string;
    path?: Array<any>;
}

export interface DeliveryPoints {
    deliveryPoint?: {
        location?: Array<number>;
        address?: string;
    };
    overviewRoute?: string;
    departureTime?: Date;
    arrivalTime?: Date;
    stopSequence?: number;
    distance?: number;
    duration?: number;
    orderId?: string;
    path?: Array<any>;
}

export interface IOptimalRoutingSchema {
    _id?: string;
    accountId?: string;
    deliveryAccountsId?: string[];
    deliveryDate?: moment.Moment | Date | string;
    time?: string;
    arrivalTime?: string;
    arrivalDate?: moment.Moment | Date | string;
    numberOfOrdersRouted?: number;
    numberOfOrdersToRoute?: number;
    numberOfOrdersToNotify?: number;
    options?: {
        screenMode?: 'SIMPLE_MODE' | 'ADVANCED_MODE',
        optimalClustering?: boolean,
        evenlySpreadOrders?: boolean
        distributeUnassignedOrders?: boolean
        pickupRoute?: boolean
        evenlySpreadType?: 'DURATION' | 'NUMBER',
        avoidFerry?: boolean,
        avoidTolls?: boolean,
        avoidHighways?: boolean
        preferSide?: 'same' | 'either',
        avoidUturn?: boolean
        forceStartTime?: boolean
    };
    routingType?: 'MANUAL' | 'AUTOMATIC' | 'DISPATCH';
    departure?: {
        location?: Array<number>;
        address?: string;
    };
    depots?: {
        location?: Array<number>;
        address?: string;
        name?: string;
        timeWindow1?: {
            start: string,
            end: string
        };
        timeWindow2?: {
            start: string,
            end: string
        };
        curbApproach?: string;
        bearing?: number;
        bearingTol?: number;
        navLatency?: number;
    }[];
    arrival?: {
        location?: Array<number>;
        address?: string;
    };
    route?: Array<IRoute>;
    externalIntegration?: {
        esri?: {
            jobId?: string
            jobStatus?: string,
            response?: any
        }
    };
    routeGenerated?: boolean;
    deliveryGroup?: string;
    lockedDrivers?: string[];
}

export interface IRoute {
    deliveryAccountId?: string;
    isChecked?: boolean;
    startDepotName?: string;
    endDepotName?: string;
    startDepotServiceTime?: number;
    endDepotServiceTime?: number;
    earliestStartTime?: string;
    latestStartTime?: string;
    endTime?: string;
    endDate?: Date;
    capacities?: string;
    costPerUnitTime?: number;
    costPerUnitDistance?: number;
    maxOrderCount?: number;
    maxTotalTime?: number;
    maxTotalTravelTime?: number;
    maxTotalDistance?: number;
    arriveDepartDelay?: number;
    fixedCost?: number;
    overTimeStartTime?: number;
    costPerUnitOvertime?: number;
    specialtyNames?: string[];
    assignmentRule?: number;
    maxWeight?: number;
    maxVolume?: number;
    driverSpeed?: number;
    breaks?: { time?: { start?: string, end?: string }, serviceTime?: number }[];
}

export const routeColor = ['blue', 'black', 'green', 'grey', 'orange', 'purple', 'turquoise', 'gold', 'aquamarine', 'cadetblue', 'darkcyan', 'deeppink', 'maroon', 'olive', 'palevioletred', 'plum', 'salmon', 'yellowgreen', 'aqua', 'bisque', 'blueviolet', 'brown', 'burlywood', 'chartreuse', 'chocolate', 'coral', 'cornflowerblue', 'crimson', 'cyan', 'darkblue', 'darkgoldenrod', 'darkgray', 'darkgreen', 'darkkhaki', 'darkmagenta', 'darkolivegreen', 'darkorange', 'darkorchid', 'darkred', 'darksalmon', 'darkseagreen', 'darkslateblue', 'darkslategray', 'darkturquoise', 'darkviolet', 'deepskyblue', 'dimgray', 'dodgerblue', 'firebrick', 'forestgreen', 'fuchsia', 'goldenrod', 'gray', 'greenyellow', 'hotpink', 'indianred', 'indigo', 'khaki', 'lavender', 'lawngreen', 'lemonchiffon', 'lightblue', 'lightcoral', 'lightgreen', 'lightpink', 'lightsalmon', 'lightseagreen', 'lightskyblue', 'lightslategray', 'lightsteelblue', 'lime', 'limegreen', 'magenta', 'mediumaquamarine', 'mediumblue', 'mediumorchid', 'mediumpurple', 'mediumseagreen', 'mediumslateblue', 'mediumspringgreen', 'mediumturquoise', 'mediumvioletred', 'midnightblue', 'moccasin', 'navajowhite', 'navy', 'olivedrab', 'orangered', 'orchid', 'palegoldenrod', 'palegreen', 'paleturquoise', 'papayawhip', 'peachpuff', 'peru', 'pink', 'powderblue', 'rosybrown', 'royalblue', 'saddlebrown', 'sandybrown', 'seagreen', 'sienna', 'silver', 'skyblue', 'slateblue', 'slategray', 'springgreen', 'steelblue', 'tan', 'teal', 'thistle', 'tomato', 'violet', 'wheat', 'yellow'];
