import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {ActiveSubscriptionService} from '@app/shared/service/subscription.service';
import {IOrder} from '@app/shared/dto/order.model';
import {CordovaLocationTrackingService} from '@app/map/route-monitoring/cordova-location-tracking.service';
import {BrowserLocationTrackingService} from '@app/map/route-monitoring/browser-location-tracking.service';
import {AskPermissionService} from '@app/map/route-monitoring/ask-mobile-permission/ask-permission.service';
import {AccountProvider} from '@app/shared/service/account.service';
import moment from 'moment-timezone';
import {Events} from "@app/shared/service/events";

@Injectable()
export class LocationTrackingService {
  private orders = [];
  private routes = [];
  private isStarted = false;
  private hasPermission;
  constructor(private platform: Platform,
              private subscriptionService: ActiveSubscriptionService,
              private cordovaLocationTrackingService: CordovaLocationTrackingService,
              private browserLocationTrackingService: BrowserLocationTrackingService,
              private askPermissionService: AskPermissionService,
              private accountProvider: AccountProvider,
              private events: Events) {
    this.events.subscribe('account:logout', async () => {
      this.stop();
    });
  }

  private shouldTrack(order: IOrder) {
    if (this.hasPermission === false) {
      return false;
    }
    if (order.deliveryAccountId == undefined || order.deliveryAccountId != this.accountProvider.account?._id) {
      return false;
    }

    const today = this.getDate(new Date());
    const deliveryDate = this.getDate(order.deliveryDate);
    return today.isSame(deliveryDate, 'day') ||
      (order.approximateArrivalTime != undefined && today.isSame(this.getDate(order.approximateArrivalTime), 'day'));
  }

  public async start(order: IOrder) {
    if (!this.shouldTrack(order)) {
      return;
    }
    if (!await this.hasSubscription()) {
      return;
    }

    if (this.platform.is('cordova')) {
      if (!await this.askPermissionService.checkForMobileLocation()) {
        return;
      }
      await this.cordovaLocationTrackingService.configure(order);
      await this.cordovaLocationTrackingService.startLocation();
      setTimeout(async () => {
        await this.applyGeofences();
      }, 300);
      this.isStarted = true;
    } else {
      await this.browserLocationTrackingService.startWatchingLocation(order);
    }
  }

  public async stop() {
    if (!await this.hasSubscription()) {
      return;
    }
    if (this.platform.is('cordova')) {
      await this.cordovaLocationTrackingService.stopLocation();
    } else {
      await this.browserLocationTrackingService.stopWatchingLocation();
    }
  }

  private getDate(date) {
    if (this.accountProvider.account.timeZone != undefined) {
      return moment.tz(date, this.accountProvider.account.timeZone);
    } else {
      return moment(date);
    }
  }

  public async configureGeofence(orders: IOrder[], routes: any[]) {
    if (!this.platform.is('cordova') || !(await this.hasReportSubscription())) {
      return;
    }
    let addRoutes = true;
    this.routes = [];
    if (routes.length > 0) {
      this.routes = this.getRouteEndLocation(routes);
    }
    this.orders = orders.filter(o => {
      if (o.deliveryAccountId != this.accountProvider.account._id) {
        return false;
      }
      return moment().isSame(this.getDate(o.deliveryDate), 'day') ||
        (o.approximateArrivalTime != undefined && moment().isSame(this.getDate(o.approximateArrivalTime), 'day'));
    }).map((o, index, array) => {
      if (o.deliveryNumber != undefined && o.deliveryNumber < 2) {
        addRoutes = false;
      }
      return {
        orderId: o._id,
        location: o.deliveryPoint,
        type: this.routes.length == 0 && index == array.length - 1 ? 'END_DEPOT' : 'PERSONAL_DELIVERY'
      };
    });

    if (this.isStarted) {
      await this.applyGeofences();
    }
  }

  getRouteEndLocation(routes) {
    if (routes == undefined || routes.length == 0) {
      return [];
    }
    const myRoute = routes.find(r => r.deliveryAccountId == this.accountProvider.account?._id && moment().isSame(this.getDate(r.deliveryDate), 'day'));
    if (myRoute == undefined || myRoute.route == undefined || myRoute.route.length == 0) {
      return [];
    }
    const route = myRoute.route[0];
    if (route.arrival?.location?.length != 2) {
      return [];
    }
    return [{
      identifier: 'END_DEPOT_' + myRoute._id,
      routeId: myRoute._id,
      location: route.arrival.location
    }];
  }

  private async applyGeofences() {
    await this.cordovaLocationTrackingService.addGeofences(this.orders, this.routes);
  }


  private async hasSubscription() {
    const sub = await this.subscriptionService.getActiveSubscriptions();
    return sub.length > 0 && sub[0].subscriptionRoles?.includes('S_LIVE_TRACKING');
  }

  private async hasReportSubscription() {
    const sub = await this.subscriptionService.getActiveSubscriptions();
    return sub.length > 0 && sub[0].subscriptionRoles?.includes('S_ADVANCED_REPORTS');
  }
}
